
import { defineComponent } from "vue";
import moment from "moment";
import axios from "axios";

interface ticketItem {
  id: number;
  name: string;
  code: string;
  used_dt: string;
  expiry_date: string;
  canceled_dt: string;
  ordered_dt: string;
  count: number;
  ids: string;
}

interface item {
  name: string;
  count: number;
  ids: string;
}

export default defineComponent({
  name: "HomeView",
  components: {},
  data() {
    return {
      items: [] as item[],
      ticket: {
        id: 0,
        name: "도시여행 당일 입장권 ",
        code: "EFFEASCG",
        used_dt: "2022-11-13 17:32:49",
        expiry_date: "2022-11-13 17:32:49",
        canceled_dt: "",
        ordered_dt: "2022-11-07 17:32:49",
      } as ticketItem,
    };
  },
  computed: {
    is_used(): boolean {
      return Boolean(this.ticket.used_dt);
    },
    is_canceled(): boolean {
      return Boolean(this.ticket.canceled_dt);
    },
    is_expiry(): boolean {
      return parseInt(moment(this.ticket.expiry_date).format("x")) < Date.now();
    },
    ticket_expiry_date(): string {
      return moment(this.ticket.expiry_date).format("YYYY년 MM월 DD일");
    },
    ticket_canceled_dt(): string {
      return moment(this.ticket.canceled_dt).format("YYYY년 MM월 DD일");
    },
    ticket_ordered_dt(): string {
      return moment(this.ticket.ordered_dt).format("YYYY년 MM월 DD일");
    },
    ticket_used_dt(): string {
      return moment(this.ticket.used_dt).format("YYYY년 MM월 DD일");
    },
  },
  methods: {
    use() {
      if (confirm("사용 하시겠습니가?")) {
        this.put();
      }
    },
    put() {
      let ids = [];
      for (let item of this.items) {
        for (let id of item.ids.split(",")) {
          ids.push(id);
        }
      }

      let url = `https://t.hubp.kr/tickets/ids/${ids.join(",")}`;
      console.log(url);
      axios
        .put(url)
        .then((res) => {
          if (res.data.result > 0) {
            this.get();
          } else {
            alert("사용 처리할 수 없습니다.");
          }
        })
        .catch((err) => {
          alert("사용 처리할 수 없습니다.");
          console.log(err);
        });
    },
    get() {
      const code = this.$route.params.code;
      axios
        .get(`https://t.hubp.kr/tickets/code/${code}`)
        .then((res) => {
          if (res.data.length > 0) {
            const ticket = res.data[0];

            this.ticket.id = ticket.id;
            this.ticket.name = ticket.name;
            this.ticket.code = ticket.code;
            this.ticket.used_dt = ticket.used_dt;
            this.ticket.expiry_date = ticket.expiry_date;
            this.ticket.canceled_dt = ticket.canceled_dt;
            this.ticket.ordered_dt = ticket.ordered_dt;

            this.items = [];
            res.data.forEach((t: ticketItem) => {
              this.items.push({
                name: t.name,
                count: t.count,
                ids: t.ids,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.get();
  },
});
